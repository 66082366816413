import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';

import {
  FetchLifestylesSetByUserIdResponse,
  UpdateLifestylesSetByUserIdCacheAction
} from '../../types';

import { getUpdatedLifestylesSetQueryData } from './utils/getUpdatedLifestylesSetQueryData';

interface UpdateLifestylesSetCacheOptions {
  fullCacheKey: QueryKey;
  itemKey: string;
}

function useUpdateLifestylesSetCache<LifestylesSetByUserIdItemType>({
  fullCacheKey,
  itemKey
}: UpdateLifestylesSetCacheOptions) {
  const queryClient = useQueryClient();

  const useUpdateLifestylesSetCache = useCallback<
    UpdateLifestylesSetByUserIdCacheAction<LifestylesSetByUserIdItemType>
  >(
    ({ updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<
          FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
        >(fullCacheKey);

      if (!previousQueryData || !previousQueryData[itemKey]) {
        return null;
      }

      const updatedQueryData =
        getUpdatedLifestylesSetQueryData<LifestylesSetByUserIdItemType>({
          updateFunction,
          previousQueryData,
          itemKey
        });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<
        FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
      >(fullCacheKey, updatedQueryData);

      return () => {
        queryClient.setQueryData<
          FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
        >(fullCacheKey, previousQueryData);
      };
    },
    [fullCacheKey, queryClient, itemKey]
  );

  return useUpdateLifestylesSetCache;
}

export default useUpdateLifestylesSetCache;
