import React from 'react';

import { Translate } from '../../../../../../../helpers/Translate';

import { LifestylesListItemNdaLifestyle } from './LifestylesListItemNda.types';

interface LifestylesListItemNdaProps {
  lifestyle: LifestylesListItemNdaLifestyle;
}

function LifestylesListItemNda({ lifestyle }: LifestylesListItemNdaProps) {
  if (!lifestyle.nda) {
    return null;
  }

  return (
    <span
      id="attachment-2-nda"
      className="bg-yellow-200 px-1 py-0.5 text-xs rounded uppercase mr-1"
    >
      <Translate id="forms.fields.nda" />
    </span>
  );
}

export default LifestylesListItemNda;
