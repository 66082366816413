import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SubmitSelectedLifestylesToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SUBMIT_SELECTED_LIFESTYLES_TO_TASK_QUERY = gql`
  mutation SubmitSelectedLifestylesToTask(
    $uuid: ID!
    $selectedLifestyleIds: [ID!]!
  ) {
    submitSelectedLifestylesToTask(
      input: { uuid: $uuid, selectedLifestyleIds: $selectedLifestyleIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
