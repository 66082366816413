import {
  CreatedAt,
  DateType,
  MoneyType,
  DeviceNanoId,
  ID,
  NanoID,
  UUID
} from '../../../types';

import {
  CategoryName,
  CategoryUUID,
  CategoryID,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

import {
  ImageFile,
  ImageName,
  ImageSize,
  ImageUUID
} from '../../images/imagesTypes';

import {
  UserCurrentTeamNanoID,
  UserFullName,
  UserID,
  UserNanoID
} from '../../users/usersTypes';

import { ImageID } from '../../images/imagesTypes';

import {
  MaxFileFile,
  MaxFileID,
  MaxFileName,
  MaxFileSize,
  MaxFileUUID
} from '../../maxFiles/maxFilesTypes';
import { ProductID } from '../../products/productsTypes';
import { TagID, TagUUID, TagName } from '../../tags/tagsTypes';

export type LifestyleID = ID;
export type LifestyleUUID = UUID;
export type LifestyleNanoID = NanoID;

export type LifestyleBlocked = boolean;
export type LifestyleFavorite = boolean;
export type LifestyleName = string;
export type LifestyleNda = boolean;
export type LifestyleCreatedAt = CreatedAt;
export type LifestyleDeviceNanoId = DeviceNanoId;
export type LifestylePremiumContentAt = DateType;
export type LifestylePrice = MoneyType;
export type LifestyleTrackedHours = number;

export type LifestyleImageID = ImageID;
export type LifestyleImageUUID = ImageUUID;
export type LifestyleImageFile = ImageFile;
export type LifestyleImageSize = ImageSize;
export type LifestyleImageName = ImageName;

export type LifestyleProductID = ProductID;

export type LifestyleLifestyleID = LifestyleID;

export type LifestyleMaxFileID = MaxFileID;
export type LifestyleMaxFileName = MaxFileName;
export type LifestyleMaxFileSize = MaxFileSize;
export type LifestyleMaxFileUUID = MaxFileUUID;
export type LifestyleMaxFileFile = MaxFileFile;

export type LifestyleProjectNameID = CategoryID;
export type LifestyleProjectNameUUID = CategoryUUID;
export type LifestyleProjectNameName = CategoryName;
export type LifestyleProjectNameLocalizedName = CategoryLocalizedName;

export type LifestyleCategoryID = CategoryID;
export type LifestyleCategoryUUID = CategoryUUID;
export type LifestyleCategoryName = CategoryName;
export type LifestyleCategoryLocalizedName = CategoryLocalizedName;

export type LifestyleClientID = UserID;
export type LifestyleClientNanoId = UserNanoID;
export type LifestyleClientFullName = UserFullName;
export type LifestyleClientCurrentTeamNanoId = UserCurrentTeamNanoID;

export type LifestyleAuthorID = CategoryID;
export type LifestyleAuthorName = CategoryName;
export type LifestyleAuthorLocalizedName = CategoryLocalizedName;

export type LifestyleStyleID = CategoryID;
export type LifestyleStyleName = CategoryName;
export type LifestyleStyleLocalizedName = CategoryLocalizedName;

export type LifestyleToneID = CategoryID;
export type LifestyleToneName = CategoryName;
export type LifestyleToneLocalizedName = CategoryLocalizedName;

export type LifestyleGammaID = CategoryID;
export type LifestyleGammaName = CategoryName;
export type LifestyleGammaLocalizedName = CategoryLocalizedName;

export type LifestyleMainColorID = CategoryID;
export type LifestyleMainColorName = CategoryName;
export type LifestyleMainColorLocalizedName = CategoryLocalizedName;

export type LifestyleAccentColorID = CategoryID;
export type LifestyleAccentColorName = CategoryName;
export type LifestyleAccentColorLocalizedName = CategoryLocalizedName;

export type LifestyleMaterialID = CategoryID;
export type LifestyleMaterialName = CategoryName;
export type LifestyleMaterialLocalizedName = CategoryLocalizedName;

export type LifestyleTagID = CategoryID;
export type LifestyleTagName = CategoryName;
export type LifestyleTagLocalizedName = CategoryLocalizedName;

export type LifestyleRenderTypeID = CategoryID;
export type LifestyleRenderTypeName = CategoryName;
export type LifestyleRenderLocalizedName = CategoryLocalizedName;

export type LifestyleLifestyleTypeID = CategoryID;
export type LifestyleLifestyleTypeName = CategoryName;
export type LifestyleLifestyleTypeLocalizedName = CategoryLocalizedName;

export type LifestyleCustomTagID = TagID;
export type LifestyleCustomTagUUID = TagUUID;
export type LifestyleCustomTagName = TagName;

export const enum LifestyleFields {
  NAME = 'name',
  AUTHOR_ID = 'authorId',
  CLIENT_IDS = 'clientIds',
  PROJECT_NAME_ID = 'projectNameId',
  CATEGORY_ID = 'categoryId',
  STYLE_IDS = 'styleIds',
  TONE_ID = 'toneId',
  GAMMA_ID = 'gammaId',
  MAIN_COLOR_IDS = 'mainColorIds',
  ACCENT_COLOR_IDS = 'accentColorIds',
  MATERIAL_IDS = 'materialIds',
  TAG_IDS = 'tagIds',
  NDA = 'nda',
  IMAGE_IDS = 'imageIds',
  MAX_FILE_IDS = 'maxFileIds',
  RENDER_TYPE_IDS = 'renderTypeIds',
  PRODUCT_IDS = 'productIds',
  LIFESTYLES_IDS = 'lifestyleIds',
  PREMIUM_CONTENT_AT = 'premiumContentAt',
  PRICE = 'price',
  TRACKED_HOURS = 'trackedHours',
  LIFESTYLE_TYPE_ID = 'lifestyleTypeId',
  CUSTOM_TAG_IDS = 'customTagIds'
}
