import { useCallback } from 'react';

import { ProjectUUID } from '../../../../../../projects/projectsTypes';
import { TaskUUID } from '../../../../../../tasks/tasksTypes';
import { LifestyleID, LifestyleUUID } from '../../../../../lifestylesTypes';

import { useSubmitLifestyleToProject } from '../../../../../hooks/useSubmitLifestyleToProject';
import { useSubmitLifestyleToTask } from '../../../../../hooks/useSubmitLifestyleToTask';

interface LifestyleAttachOptionsLifestyle {
  uuid: LifestyleUUID;
  id: LifestyleID;
}

interface LifestyleAttachOptions {
  lifestyle: LifestyleAttachOptionsLifestyle;
}

interface HandleLifestyleAttachProps {
  projectUuid: ProjectUUID;
  taskUuid: TaskUUID | null;
}

function useLifestyleAttach({ lifestyle }: LifestyleAttachOptions) {
  const {
    submitLifestyleToTask,
    submitLifestyleToTaskError,
    submitLifestyleToTaskIsLoading,
    submitLifestyleToTaskReset
  } = useSubmitLifestyleToTask({ lifestyle });

  const {
    submitLifestyleToProject,
    submitLifestyleToProjectError,
    submitLifestyleToProjectIsLoading,
    submitLifestyleToProjectReset
  } = useSubmitLifestyleToProject({ lifestyle });

  const lifestyleAttach = useCallback<
    ({ projectUuid, taskUuid }: HandleLifestyleAttachProps) => Promise<unknown>
  >(
    async ({ projectUuid, taskUuid }) => {
      if (taskUuid) {
        return submitLifestyleToTask(taskUuid);
      }

      return submitLifestyleToProject(projectUuid);
    },
    [submitLifestyleToTask, submitLifestyleToProject]
  );

  const lifestyleAttachReset = useCallback<() => void>(() => {
    submitLifestyleToProjectReset();
    submitLifestyleToTaskReset();
  }, [submitLifestyleToProjectReset, submitLifestyleToTaskReset]);

  return {
    lifestyleAttach,
    lifestyleAttachReset,
    lifestyleAttachError:
      submitLifestyleToTaskError || submitLifestyleToProjectError,
    lifestyleAttachIsLoading:
      submitLifestyleToTaskIsLoading || submitLifestyleToProjectIsLoading
  };
}

export default useLifestyleAttach;
