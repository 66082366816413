import {
  FetchLifestylesSetLifestyleClientId,
  FetchLifestylesSetByUserIdScopeType
} from './lifestylesSetsTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class LifestylesSetScope {
  static userLibraryScope(
    lifestyleClientId: FetchLifestylesSetLifestyleClientId
  ): FetchLifestylesSetByUserIdScopeType {
    return `user-${lifestyleClientId}-library`;
  }

  static companyLibraryScope(
    teamNanoId: TeamNanoID
  ): FetchLifestylesSetByUserIdScopeType {
    return `company-${teamNanoId}-library`;
  }

  static submitToTaskScope(
    lifestyleClientId: FetchLifestylesSetLifestyleClientId
  ): FetchLifestylesSetByUserIdScopeType {
    return `${lifestyleClientId}-submit-to-task`;
  }

  static submitToProjectScope(
    lifestyleClientId: FetchLifestylesSetLifestyleClientId
  ): FetchLifestylesSetByUserIdScopeType {
    return `${lifestyleClientId}-submit-to-project`;
  }
}
