import {
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  TaskGqlError,
  FetchTaskCacheKey,
  TaskUUID,
  TaskSelectedLifestylesID,
  TaskNanoID
} from '../../tasksTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedLifestylesToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  selectedLifestyleIds: TaskSelectedLifestylesID[];
}

interface SubmitSelectedLifestylesToTaskResponse<
  SubmitSelectedLifestylesToTaskRecordType
> {
  SubmitSelectedLifestylesToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: SubmitSelectedLifestylesToTaskRecordType;
    errors: SubmitSelectedLifestylesToTaskErrors;
  };
}

interface SubmitSelectedLifestylesToTaskErrors {
  fullMessages: TaskGqlError;
}

interface SubmitSelectedLifestylesToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys: FetchTaskCacheKey[];
}

const action = 'submitSelectedLifestylesToTask';

function useSubmitSelectedLifestylesToTask<
  SubmitSelectedLifestylesToTaskRecordType
>({ query, cacheKeys }: SubmitSelectedLifestylesToTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedLifestylesToTaskInput,
    SubmitSelectedLifestylesToTaskResponse<SubmitSelectedLifestylesToTaskRecordType>,
    SubmitSelectedLifestylesToTaskErrors,
    SubmitSelectedLifestylesToTaskRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedLifestylesToTaskData: createQueryData,
    submitSelectedLifestylesToTaskError: createQueryError,
    submitSelectedLifestylesToTaskErrorMessage: createQueryErrorMessage,
    submitSelectedLifestylesToTaskLoading: createQueryLoading,
    submitSelectedLifestylesToTask: createQuery,
    submitSelectedLifestylesToTaskReset: createQueryReset
  };
}

export default useSubmitSelectedLifestylesToTask;
