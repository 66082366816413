import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useLifestylesListItemUpdateImageVersions } from './hooks/useLifestylesListItemUpdateImageVersions';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { LifestylesListItemUpdateImageVersionsLifestyle } from './LifestylesListItemUpdateImageVersions.types';

interface LifestylesListItemUpdateImageVersionsProps {
  lifestyle: LifestylesListItemUpdateImageVersionsLifestyle;
}

function LifestylesListItemUpdateImageVersions({
  lifestyle
}: LifestylesListItemUpdateImageVersionsProps) {
  const hasImage = lifestyle.image?.file && Files.isImage(lifestyle.image.file);

  const { updateImageVersions, updateImageVersionsLoading } =
    useLifestylesListItemUpdateImageVersions({ lifestyle });

  if (!hasImage) {
    return null;
  }

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      icon={IconsEnum.PHOTOGRAPH_OUTLINE}
      iconClassName="h-5 w-5 stroke-1.75"
      tooltipSingleton
      tooltipI18nText={words.updateImageVersions}
      tooltipPlacement={TooltipPlacement.LEFT}
      disabled={updateImageVersionsLoading}
      onClick={updateImageVersions}
    />
  );
}

export default LifestylesListItemUpdateImageVersions;
