import { FetchLifestylesSetByUserIdResponse } from '../../../../types';

interface GetUpdatedLifestylesSetQueryDataOptions<
  LifestylesSetByUserIdItemType
> {
  updateFunction: (
    item: LifestylesSetByUserIdItemType
  ) => Partial<LifestylesSetByUserIdItemType>;
  previousQueryData: FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>;
  itemKey: string;
}

function getUpdatedLifestylesSetQueryData<LifestylesSetByUserIdItemType>({
  updateFunction,
  previousQueryData,
  itemKey
}: GetUpdatedLifestylesSetQueryDataOptions<LifestylesSetByUserIdItemType>): FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType> | null {
  const previousItem = previousQueryData?.[itemKey];

  if (!previousItem) {
    return null;
  }

  const updateData = updateFunction ? updateFunction(previousItem) : {};

  const updatedItem = {
    ...previousItem,
    optimisticUpdate: true,
    ...updateData
  };

  return {
    ...previousQueryData,
    [itemKey]: updatedItem
  };
}

export default getUpdatedLifestylesSetQueryData;
