import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import find from 'lodash/find';
import without from 'lodash/without';
import isArray from 'lodash/isArray';

import { UUID, ID } from '../../../../types';

import {
  ToggleLifestyleInLifestylesSetGqlQuery,
  ToggleLifestyleInLifestylesSetGqlStatus,
  ToggleLifestyleInLifestylesSetCacheKeys,
  ToggleLifestyleInLifestylesSetGqlError,
  LifestylesSetSelectedLifestylesUUID,
  UpdateLifestylesSetCacheAction
} from '../../lifestylesSetsTypes';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

import { TempSelectedLifestyle } from './utils/TempSelectedLifestyle';

import { ToggleLifestyleInLifestylesSetLifestyle } from './useToggleLifestyleInLifestylesSet.types';

interface ToggleLifestyleInLifestylesSetInput {
  clientMutationId?: string;
  uuid: UUID;
  lifestyleId: ID;
}

interface ToggleLifestyleInLifestylesSetError {
  fullMessages: ToggleLifestyleInLifestylesSetGqlError;
}

interface ToggleLifestyleInLifestylesSetResponse<
  ToggleLifestyleInLifestylesSetItemType
> {
  toggleLifestyleInLifestylesSet: {
    status: ToggleLifestyleInLifestylesSetGqlStatus;
    record: ToggleLifestyleInLifestylesSetItemType;
    errors: ToggleLifestyleInLifestylesSetError;
  };
}

interface ToggleLifestyleInLifestylesSetContext {
  rollback?: () => void;
}

interface ToggleLifestyleInLifestylesSetOptions<
  ToggleLifestyleInLifestylesSetItemType extends ToggleLifestyleInLifestylesSetItem
> {
  query: ToggleLifestyleInLifestylesSetGqlQuery;
  cacheKeys: ToggleLifestyleInLifestylesSetCacheKeys;
  lifestyle?: ToggleLifestyleInLifestylesSetLifestyle;
  updateLifestylesSetCache?: UpdateLifestylesSetCacheAction<ToggleLifestyleInLifestylesSetItemType>;
}

const action = 'toggleLifestyleInLifestylesSet';

interface ToggleLifestyleInLifestylesSetItem {
  selectedLifestyles: {
    uuid: LifestylesSetSelectedLifestylesUUID;
  }[];
}

function useToggleLifestyleInLifestylesSet<
  ToggleLifestyleInLifestylesSetItemType extends ToggleLifestyleInLifestylesSetItem
>({
  query,
  cacheKeys,
  lifestyle,
  updateLifestylesSetCache
}: ToggleLifestyleInLifestylesSetOptions<ToggleLifestyleInLifestylesSetItemType>) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return (
      lifestyle &&
      updateLifestylesSetCache?.({
        updateFunction: (prevLifestylesSet) => {
          const prevSelectedLifestyles = prevLifestylesSet.selectedLifestyles;

          const selectedLifestyle = find(prevSelectedLifestyles, [
            'lifestyle.uuid',
            lifestyle.uuid
          ]);

          const newSelectedLifestyles = selectedLifestyle
            ? without(prevSelectedLifestyles, selectedLifestyle)
            : [
                ...prevSelectedLifestyles,
                TempSelectedLifestyle.create(lifestyle)
              ];

          return {
            ...prevLifestylesSet,
            selectedLifestyles: newSelectedLifestyles
          };
        }
      })
    );
  }, [lifestyle, updateLifestylesSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    ToggleLifestyleInLifestylesSetResponse<ToggleLifestyleInLifestylesSetItemType>,
    ToggleLifestyleInLifestylesSetError | ClientError | Error,
    ToggleLifestyleInLifestylesSetInput,
    ToggleLifestyleInLifestylesSetContext
  >(
    (queryInput) =>
      updateItem<
        ToggleLifestyleInLifestylesSetInput,
        ToggleLifestyleInLifestylesSetResponse<ToggleLifestyleInLifestylesSetItemType>
      >({
        query,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    toggleLifestyleInLifestylesSetError: error,
    toggleLifestyleInLifestylesSetLoading: isLoading,
    toggleLifestyleInLifestylesSetErrorMessage: parseRequestError(error),
    toggleLifestyleInLifestylesSet: mutateAsync,
    toggleLifestyleInLifestylesSetReset: reset
  };
}

export default useToggleLifestyleInLifestylesSet;
