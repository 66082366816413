import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  TOGGLE_LIFESTYLE_FAVORITE_QUERY,
  ToggleLifestyleFavoriteQueryResponse
} from '../../../../../queries/toggleLifestyleFavorite.query';

import { useToggleLifestyleFavorite } from '../../../../../hooks/useToggleLifestyleFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { LifestyleCache } from '../../../../../LifestyleCache';
import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';

import { LifestylesListItemLightboxFavoriteButtonLifestyle } from './LifestylesListItemLightboxFavoriteButton.types';

interface useLifestylesListItemLightboxFavoriteButtonProps {
  lifestyle: LifestylesListItemLightboxFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateLifestyleCache?: UpdateLifestyleCacheAction<LifestylesListItemLightboxFavoriteButtonLifestyle>;
}

function useLifestylesListItemLightboxFavoriteButton({
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateLifestyleCache
}: useLifestylesListItemLightboxFavoriteButtonProps) {
  const { toggleLifestyleFavorite, toggleLifestyleFavoriteErrorMessage } =
    useToggleLifestyleFavorite<ToggleLifestyleFavoriteQueryResponse>({
      query: TOGGLE_LIFESTYLE_FAVORITE_QUERY,
      cacheKeys: compact([
        LifestyleCache.indexCacheKey(),
        LifestylesSetCache.showCacheKey(),
        ...(lifestylesCacheKeys || []),
        ...(lifestylesSetCacheKeys || [])
      ]),
      updateLifestyleCache
    });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleLifestyleFavorite({ uuid: lifestyle.uuid }),
    [lifestyle, toggleLifestyleFavorite]
  );

  useShowToastOnErrorChange({ error: toggleLifestyleFavoriteErrorMessage });

  return {
    handleToggleFavorite
  };
}

export default useLifestylesListItemLightboxFavoriteButton;
