import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { LifestyleUUID } from '../../../lifestylesTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadLifestyle } from '../../../hooks/useDownloadLifestyle';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface DownloadLifestyleButtonProps {
  lifestyleUuid: LifestyleUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadLifestyleButton({
  lifestyleUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipSingleton,
  tooltipPlacement
}: DownloadLifestyleButtonProps) {
  const {
    downloadLifestyleLoading,
    downloadLifestyleErrorMessage,
    downloadLifestyle
  } = useDownloadLifestyle();

  useShowToastOnErrorChange({ error: downloadLifestyleErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadLifestyle({
      uuid: lifestyleUuid,
      deviceNanoId: downloadNanoId
    });
  }, [lifestyleUuid, downloadNanoId, downloadLifestyle]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
      disabled={downloadLifestyleLoading}
      onClick={handleClick}
      tooltipSingleton={tooltipSingleton}
      tooltipPlacement={tooltipPlacement}
    />
  );
}

export default DownloadLifestyleButton;
