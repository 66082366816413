import React from 'react';

import { ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../lifestylesSets/lifestylesSetsTypes';

import {
  OnSelectedLifestylesSidebarOpenAction,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../lifestylesTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { useLifestyleSelectButton } from './hooks/useLifestyleSelectButton';

import { words } from '../../../../../locales/keys';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import {
  LifestyleSelectButtonLifestyle,
  LifestyleSelectButtonLifestylesSet,
  LifestyleSelectButtonOnSelect
} from './LifestyleSelectButton.types';

interface LifestyleSelectButtonProps {
  lifestyle: LifestyleSelectButtonLifestyle;
  lifestylesSet: LifestyleSelectButtonLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen?: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose?: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestyleSelectButtonLifestylesSet>;
  onSelect?: LifestyleSelectButtonOnSelect;
  selectedClassName?: ClassName;
  unselectedClassName?: ClassName;
  iconClassName?: ClassName;
  selectedIcon?: IconsEnum;
  unselectedIcon?: IconsEnum;
}

function LifestyleSelectButton({
  lifestyle,
  lifestylesSet,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  onSelect,
  selectedClassName,
  unselectedClassName,
  iconClassName,
  selectedIcon,
  unselectedIcon
}: LifestyleSelectButtonProps) {
  const { isSelected, handleToggleSelected } = useLifestyleSelectButton({
    lifestyle,
    lifestylesSet,
    lifestylesSetCacheKeys,
    onSelectedLifestylesSidebarOpen,
    onSelectedLifestylesSidebarClose,
    updateLifestylesSetCache,
    onSelect
  });

  return (
    <PureTooltipIconButtonHelper
      className={isSelected ? selectedClassName : unselectedClassName}
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? selectedIcon || IconsEnum.CHECK : unselectedIcon}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={words.selectToAddToTheProject}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default LifestyleSelectButton;
