import { useCallback } from 'react';

import { LifestylesListItemUpdateImageVersionsLifestyle } from '../../LifestylesListItemUpdateImageVersions.types';

import { RECREATE_IMAGE_VERSIONS_QUERY } from '../../../../../../../../images/queries/recreateImageVersions.query';

import { useShowToastOnErrorChange } from '../../../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useRecreateImageVersions } from '../../../../../../../../images/hooks/useRecreateImageVersions';

import { ImageItemImageVersions } from '../../../../../../../../../helpers/ImageHelper';

interface LifestylesListItemUpdateImageVersionsOptions {
  lifestyle: LifestylesListItemUpdateImageVersionsLifestyle;
}

function useLifestylesListItemUpdateImageVersions({
  lifestyle
}: LifestylesListItemUpdateImageVersionsOptions) {
  const {
    recreateImageVersions,
    recreateImageVersionsLoading,
    recreateImageVersionsErrorMessage
  } = useRecreateImageVersions({
    query: RECREATE_IMAGE_VERSIONS_QUERY
  });

  useShowToastOnErrorChange({ error: recreateImageVersionsErrorMessage });

  const lifestyleImageUuid = lifestyle?.image?.uuid;
  const handleUpdateImageVersions = useCallback(() => {
    if (lifestyleImageUuid) {
      return recreateImageVersions({
        uuid: lifestyleImageUuid,
        async: true,
        versions: [
          ImageItemImageVersions.MiniThumb48x48,
          ImageItemImageVersions.MiniThumb96x96,
          ImageItemImageVersions.MiniThumb144x144,
          ImageItemImageVersions.MiniThumb320x320,
          ImageItemImageVersions.MiniThumb640x640,
          ImageItemImageVersions.MiniThumb960x960,
          ImageItemImageVersions.BigThumb538x435
        ]
      });
    }
  }, [lifestyleImageUuid, recreateImageVersions]);

  return {
    updateImageVersions: handleUpdateImageVersions,
    updateImageVersionsLoading: recreateImageVersionsLoading
  };
}

export default useLifestylesListItemUpdateImageVersions;
