import { useCallback } from 'react';

import { LifestyleID, LifestyleUUID } from '../../lifestylesTypes';
import { ProjectUUID } from '../../../projects/projectsTypes';

import {
  FetchLifestylesSetByUserIdQueryResponse,
  FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY
} from '../../../lifestylesSets/queries/fetchLifestylesSetByUserId.query';
import { TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY } from '../../../lifestylesSets/queries/toggleLifestyleInLifestylesSet.query';
import {
  SubmitSelectedLifestylesToProjectQueryResponse,
  SUBMIT_SELECTED_LIFESTYLES_TO_PROJECT_QUERY
} from '../../../projects/queries/submitSelectedLifestylesToProject.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useLifestylesSetByUserId } from '../../../lifestylesSets/hooks/useLifestylesSetByUserId';
import { useToggleLifestyleInLifestylesSet } from '../../../lifestylesSets/hooks/useToggleLifestyleInLifestylesSet';
import { useSubmitSelectedLifestylesToProject } from '../../../projects/hooks/useSubmitSelectedLifestylesToProject';

import { LifestylesSetCache } from '../../../lifestylesSets/LifestylesSetCache';
import { LifestylesSetScope } from '../../../lifestylesSets/LifestylesSetScope';

interface SubmitLifestyleToProjectOptionsLifestyle {
  uuid: LifestyleUUID;
  id: LifestyleID;
}

interface SubmitLifestyleToProjectOptions {
  lifestyle: SubmitLifestyleToProjectOptionsLifestyle;
}

function useSubmitLifestyleToProject({
  lifestyle
}: SubmitLifestyleToProjectOptions) {
  const currentUser = useCurrentUser();

  const {
    lifestylesSet,
    lifestylesSetError,
    lifestylesSetLoading,
    updateLifestylesSetCache
  } = useLifestylesSetByUserId<FetchLifestylesSetByUserIdQueryResponse>({
    cacheKey: LifestylesSetCache.submitToProjectCacheKey(),
    query: FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: LifestylesSetScope.submitToProjectScope(currentUser.id)
  });

  const {
    toggleLifestyleInLifestylesSetErrorMessage,
    toggleLifestyleInLifestylesSet,
    toggleLifestyleInLifestylesSetLoading
  } = useToggleLifestyleInLifestylesSet({
    query: TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
    cacheKeys: [LifestylesSetCache.submitToProjectCacheKey()],
    lifestyle,
    updateLifestylesSetCache
  });

  const {
    submitSelectedLifestylesToProjectErrorMessage,
    submitSelectedLifestylesToProjectLoading,
    submitSelectedLifestylesToProject,
    submitSelectedLifestylesToProjectReset
  } = useSubmitSelectedLifestylesToProject<SubmitSelectedLifestylesToProjectQueryResponse>(
    {
      query: SUBMIT_SELECTED_LIFESTYLES_TO_PROJECT_QUERY,
      cacheKeys: [LifestylesSetCache.submitToProjectCacheKey()]
    }
  );

  const submitLifestyleToProject = useCallback<
    (projectUuid: ProjectUUID) => Promise<unknown>
  >(
    async (projectUuid) => {
      const data = await toggleLifestyleInLifestylesSet({
        lifestyleId: lifestyle.id,
        uuid: lifestylesSet.uuid
      });

      return submitSelectedLifestylesToProject({
        uuid: projectUuid,
        selectedLifestyleIds:
          data.toggleLifestyleInLifestylesSet?.record?.selectedLifestyles?.map(
            (selectedLifestyle) => selectedLifestyle.id
          )
      });
    },
    [
      lifestyle.id,
      lifestylesSet,
      submitSelectedLifestylesToProject,
      toggleLifestyleInLifestylesSet
    ]
  );

  return {
    submitLifestyleToProject,
    submitLifestyleToProjectReset: submitSelectedLifestylesToProjectReset,
    submitLifestyleToProjectError:
      lifestylesSetError ||
      toggleLifestyleInLifestylesSetErrorMessage ||
      submitSelectedLifestylesToProjectErrorMessage,
    submitLifestyleToProjectIsLoading:
      lifestylesSetLoading ||
      toggleLifestyleInLifestylesSetLoading ||
      submitSelectedLifestylesToProjectLoading
  };
}

export default useSubmitLifestyleToProject;
