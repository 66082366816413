import { useCallback } from 'react';

import { TaskUUID } from '../../../tasks/tasksTypes';
import { LifestyleID, LifestyleUUID } from '../../lifestylesTypes';

import {
  FetchLifestylesSetByUserIdQueryResponse,
  FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY
} from '../../../lifestylesSets/queries/fetchLifestylesSetByUserId.query';
import { TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY } from '../../../lifestylesSets/queries/toggleLifestyleInLifestylesSet.query';
import {
  SubmitSelectedLifestylesToTaskQueryResponse,
  SUBMIT_SELECTED_LIFESTYLES_TO_TASK_QUERY
} from '../../../tasks/queries/submitSelectedLifestylesToTask.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useLifestylesSetByUserId } from '../../../lifestylesSets/hooks/useLifestylesSetByUserId';
import { useToggleLifestyleInLifestylesSet } from '../../../lifestylesSets/hooks/useToggleLifestyleInLifestylesSet';
import { useSubmitSelectedLifestylesToTask } from '../../../tasks/hooks/useSubmitSelectedLifestylesToTask';

import { LifestylesSetCache } from '../../../lifestylesSets/LifestylesSetCache';
import { LifestylesSetScope } from '../../../lifestylesSets/LifestylesSetScope';

interface SubmitLifestyleToTaskOptionsLifestyle {
  uuid: LifestyleUUID;
  id: LifestyleID;
}

interface SubmitLifestyleToTaskOptions {
  lifestyle: SubmitLifestyleToTaskOptionsLifestyle;
}

function useSubmitLifestyleToTask({ lifestyle }: SubmitLifestyleToTaskOptions) {
  const currentUser = useCurrentUser();

  const {
    lifestylesSet,
    lifestylesSetError,
    lifestylesSetLoading,
    updateLifestylesSetCache
  } = useLifestylesSetByUserId<FetchLifestylesSetByUserIdQueryResponse>({
    cacheKey: LifestylesSetCache.submitToTaskCacheKey(),
    query: FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: LifestylesSetScope.submitToTaskScope(currentUser.id)
  });

  const {
    toggleLifestyleInLifestylesSetErrorMessage,
    toggleLifestyleInLifestylesSet,
    toggleLifestyleInLifestylesSetLoading
  } = useToggleLifestyleInLifestylesSet({
    query: TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
    cacheKeys: [LifestylesSetCache.submitToTaskCacheKey()],
    lifestyle,
    updateLifestylesSetCache
  });

  const {
    submitSelectedLifestylesToTaskErrorMessage,
    submitSelectedLifestylesToTaskLoading,
    submitSelectedLifestylesToTask,
    submitSelectedLifestylesToTaskReset
  } = useSubmitSelectedLifestylesToTask<SubmitSelectedLifestylesToTaskQueryResponse>(
    {
      query: SUBMIT_SELECTED_LIFESTYLES_TO_TASK_QUERY,
      cacheKeys: [LifestylesSetCache.submitToTaskCacheKey()]
    }
  );

  const submitLifestyleToTask = useCallback<
    (taskUuid: TaskUUID) => Promise<unknown>
  >(
    async (taskUuid) => {
      const data = await toggleLifestyleInLifestylesSet({
        lifestyleId: lifestyle.id,
        uuid: lifestylesSet.uuid
      });

      return submitSelectedLifestylesToTask({
        uuid: taskUuid,
        selectedLifestyleIds:
          data.toggleLifestyleInLifestylesSet?.record?.selectedLifestyles?.map(
            (selectedLifestyle) => selectedLifestyle.id
          )
      });
    },
    [
      lifestyle.id,
      lifestylesSet,
      submitSelectedLifestylesToTask,
      toggleLifestyleInLifestylesSet
    ]
  );

  return {
    submitLifestyleToTask,
    submitLifestyleToTaskReset: submitSelectedLifestylesToTaskReset,
    submitLifestyleToTaskError:
      lifestylesSetError ||
      toggleLifestyleInLifestylesSetErrorMessage ||
      submitSelectedLifestylesToTaskErrorMessage,
    submitLifestyleToTaskIsLoading:
      lifestylesSetLoading ||
      toggleLifestyleInLifestylesSetLoading ||
      submitSelectedLifestylesToTaskLoading
  };
}

export default useSubmitLifestyleToTask;
