import {
  LifestylesSetUUID,
  FetchLifestylesSetLifestyleClientId
} from './lifestylesSetsTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class LifestylesSetCache {
  static indexCacheKey() {
    return 'lifestylesSets';
  }

  static showCacheKey() {
    return 'lifestylesSet';
  }

  static submitToTaskCacheKey() {
    return 'lifestylesSet-submit-to-task';
  }

  static submitToProjectCacheKey() {
    return 'lifestylesSet-submit-to-project';
  }

  static showUserLibraryCacheKey(
    lifestyleClientId: FetchLifestylesSetLifestyleClientId
  ) {
    return `lifestylesSetUserLibrary-${lifestyleClientId}`;
  }

  static showCompanyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `lifestylesSetCompanyLibrary-${companyNanoId}`;
  }

  static showQueryKey(lifestylesSetUuid: LifestylesSetUUID) {
    return ['lifestylesSet', lifestylesSetUuid];
  }
}
