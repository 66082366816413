import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { Files } from '../../../../../../../utils/Files';

import { LifestylesListItemOpenLightboxButtonLifestyle } from './LifestylesListItemOpenLightboxButton.types';

interface LifestylesListItemOpenLightboxButtonProps {
  lifestyle: LifestylesListItemOpenLightboxButtonLifestyle;
  onLightboxOpen: () => void;
}

function LifestylesListItemOpenLightboxButton({
  lifestyle,
  onLightboxOpen
}: LifestylesListItemOpenLightboxButtonProps) {
  const hasImage = lifestyle.image?.file && Files.isImage(lifestyle.image.file);

  if (!hasImage) {
    return null;
  }

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      icon={IconsEnum.ARROWS_EXPAND_SOLID}
      iconClassName="h-5 w-5"
      tooltipI18nText="words.expand_view"
      tooltipSingleton
      onClick={onLightboxOpen}
    />
  );
}

export default LifestylesListItemOpenLightboxButton;
