import React, { memo, Fragment, useMemo, useCallback } from 'react';
import filter from 'lodash/filter';

import { IsFetched, ErrorMessage } from '../../../../../types';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../lifestylesSets/lifestylesSetsTypes';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction,
  OnSelectedLifestylesSidebarOpenAction,
  OnSelectedLifestylesSidebarCloseAction,
  LifestyleNanoID
} from '../../../lifestylesTypes';

import { LifestylesListItem } from '../LifestylesListItem';

import { LifestylesListItemLightboxBottomButtons } from './components/LifestylesListItemLightboxBottomButtons';
import { LifestylesListItemLightboxTitle } from './components/LifestylesListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingGridSkeleton } from '../../../../../helpers/LoadingGridSkeleton';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';
import {
  LightboxToggleBackdrop,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

import {
  LifestylesListLifestyles,
  LifestylesListLifestyle,
  LifestylesListLifestyleImages,
  LifestylesListLifestylesSet,
  LifestylesListOnLifestylesSelect
} from './LifestylesList.types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

interface LifestylesListDefaultProps {
  companyNanoId?: TeamNanoID;
  lifestyles: LifestylesListLifestyles;
  lifestylesFetched: IsFetched;
  lifestylesError: ErrorMessage;
  lifestylesIsPlaceholderData: boolean;
  lifestylesTotalCount: number;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  updateLifestyleCache?: UpdateLifestyleCacheAction<LifestylesListLifestyle>;
  togglePreventModalClose?: LightboxToggleBackdrop;
  onLifestyleEditButtonMouseEnter?: (lifestyleNanoId: LifestyleNanoID) => void;
  loadingGridSkeletonItemsCount?: number;
  withRealAspectRatio?: boolean;
  withLifestylePreviewLink?: boolean;
}

interface LifestylesListWithSelectProps {
  lifestylesSetFetched: IsFetched;
  lifestylesSetError: ErrorMessage;
  lifestylesSetIsPlaceholderData: boolean;
  lifestylesSet: LifestylesListLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onLifestylesSelect?: LifestylesListOnLifestylesSelect;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListLifestylesSet>;
}

interface LifestylesListWithoutSelectProps {
  lifestylesSetFetched?: never;
  lifestylesSetError?: never;
  lifestylesSetIsPlaceholderData?: never;
  lifestylesSet?: never;
  lifestylesSetCacheKeys?: never;
  onLifestylesSelect?: never;
  onSelectedLifestylesSidebarOpen?: never;
  onSelectedLifestylesSidebarClose?: never;
  updateLifestylesSetCache?: never;
}

type LifestylesListProps = LifestylesListDefaultProps &
  (LifestylesListWithSelectProps | LifestylesListWithoutSelectProps);

function LifestylesList({
  companyNanoId,
  lifestyles,
  lifestylesFetched,
  lifestylesSetFetched,
  lifestylesError,
  lifestylesSetError,
  lifestylesIsPlaceholderData,
  lifestylesSetIsPlaceholderData,
  lifestylesTotalCount,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onLifestylesSelect,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  updateLifestyleCache,
  togglePreventModalClose,
  onLifestyleEditButtonMouseEnter,
  loadingGridSkeletonItemsCount,
  withRealAspectRatio,
  withLifestylePreviewLink
}: LifestylesListProps) {
  const lifestylesInLightbox = useMemo<LifestylesListLifestyles>(() => {
    return filter<LifestylesListLifestyle>(
      lifestyles,
      (lifestyle) =>
        lifestyle.image?.file && Files.isImage(lifestyle.image.file)
    );
  }, [lifestyles]);

  const lightboxItems = useMemo<LifestylesListLifestyleImages>(() => {
    return lifestylesInLightbox.map((lifestyle) => lifestyle.image);
  }, [lifestylesInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const lifestyle = lifestylesInLightbox[index];
      if (!lifestyle) {
        return [];
      }

      return (
        <LifestylesListItemLightboxBottomButtons
          lifestyle={lifestyle}
          lifestylesSet={lifestylesSet}
          lifestylesCacheKeys={lifestylesCacheKeys}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
          onSelectedLifestylesSidebarOpen={onSelectedLifestylesSidebarOpen}
          onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
          updateLifestylesSetCache={updateLifestylesSetCache}
          updateLifestyleCache={updateLifestyleCache}
        />
      );
    },
    [
      lifestylesInLightbox,
      lifestylesSet,
      lifestylesCacheKeys,
      lifestylesSetCacheKeys,
      onSelectedLifestylesSidebarOpen,
      onSelectedLifestylesSidebarClose,
      updateLifestylesSetCache,
      updateLifestyleCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const lifestyle = lifestylesInLightbox[index];
      if (!lifestyle) {
        return null;
      }

      return <LifestylesListItemLightboxTitle lifestyle={lifestyle} />;
    },
    [lifestylesInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage
        addClassName="m-4"
        message={lifestylesError || lifestylesSetError}
      />
      <Loading
        loaded={!lifestylesIsPlaceholderData && !lifestylesSetIsPlaceholderData}
      />
      <LoadingGridSkeleton
        loaded={
          onSelectedLifestylesSidebarOpen
            ? (lifestylesIsPlaceholderData || lifestylesFetched) &&
              (lifestylesSetIsPlaceholderData || lifestylesSetFetched)
            : lifestylesIsPlaceholderData || lifestylesFetched
        }
        itemsCount={loadingGridSkeletonItemsCount}
      >
        {lifestylesTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <div className="px-4 grid grid-cols-auto-fill grid-cell-min-48 xl:grid-cell-min-52 2xl:grid-cell-min-64 3xl:grid-cell-min-72 gap-4 xl:gap-8 2xl:gap-12">
            {lifestyles.map((lifestyle) => (
              <LifestylesListItem
                companyNanoId={companyNanoId}
                key={lifestyle.uuid}
                lifestyle={lifestyle}
                lifestylesSet={lifestylesSet}
                lifestylesCacheKeys={lifestylesCacheKeys}
                lifestylesSetCacheKeys={lifestylesSetCacheKeys}
                onLightboxOpen={handleLightboxOpenOnSlide}
                onSelectedLifestylesSidebarOpen={
                  onSelectedLifestylesSidebarOpen
                }
                onSelectedLifestylesSidebarClose={
                  onSelectedLifestylesSidebarClose
                }
                updateLifestylesSetCache={updateLifestylesSetCache}
                updateLifestyleCache={updateLifestyleCache}
                onLifestylesSelect={onLifestylesSelect}
                onLifestyleEditButtonMouseEnter={
                  onLifestyleEditButtonMouseEnter
                }
                withRealAspectRatio={withRealAspectRatio}
                withLifestylePreviewLink={withLifestylePreviewLink}
              />
            ))}
          </div>
        )}
      </LoadingGridSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<LifestylesListProps>(LifestylesList);
