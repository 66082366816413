import { useCallback } from 'react';

import {
  LifestyleFavorite,
  LifestyleUUID,
  UpdateLifestyleCacheAction,
  ToggleLifestyleFavoriteGqlQuery,
  ToggleLifestyleFavoriteGqlStatus,
  ToggleLifestyleFavoriteCacheKeys,
  ToggleLifestyleFavoriteGqlError
} from '../../lifestylesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleLifestyleFavoriteInput {
  clientMutationId?: string;
  uuid: LifestyleUUID;
}

interface ToggleLifestyleFavoriteError {
  fullMessages: ToggleLifestyleFavoriteGqlError;
}

interface ToggleLifestyleFavoriteResponse<ToggleLifestyleFavoriteItemType> {
  toggleLifestyleFavorite: {
    status: ToggleLifestyleFavoriteGqlStatus;
    recordUuid: LifestyleUUID;
    record: ToggleLifestyleFavoriteItemType;
    errors: ToggleLifestyleFavoriteError;
  };
}

interface ToggleLifestyleFavoriteOptions<ToggleLifestyleFavoriteItemType> {
  query: ToggleLifestyleFavoriteGqlQuery;
  cacheKeys?: ToggleLifestyleFavoriteCacheKeys;
  updateLifestyleCache?: UpdateLifestyleCacheAction<ToggleLifestyleFavoriteItemType>;
}

const action = 'toggleLifestyleFavorite';

interface ToggleLifestyleFavoriteItem {
  favorite: LifestyleFavorite;
}

function useToggleLifestyleFavorite<
  ToggleLifestyleFavoriteItemType extends ToggleLifestyleFavoriteItem
>({
  query,
  cacheKeys,
  updateLifestyleCache
}: ToggleLifestyleFavoriteOptions<ToggleLifestyleFavoriteItemType>) {
  const handleOptimisticUpdate = useCallback<
    (input: ToggleLifestyleFavoriteInput) => null | (() => void)
  >(
    (input) => {
      return updateLifestyleCache?.({
        selector: { uuid: input?.uuid },
        updateFunction: (prevLifestyle) => ({
          ...prevLifestyle,
          favorite: !prevLifestyle?.favorite
        })
      });
    },
    [updateLifestyleCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleLifestyleFavoriteInput,
    ToggleLifestyleFavoriteResponse<ToggleLifestyleFavoriteItemType>,
    ToggleLifestyleFavoriteError,
    ToggleLifestyleFavoriteItemType
  >({
    action,
    cacheKeys,
    query,
    onOptimisticUpdate: updateLifestyleCache
      ? handleOptimisticUpdate
      : undefined
  });

  return {
    toggleLifestyleFavoriteData: updateQueryData,
    toggleLifestyleFavoriteError: updateQueryError,
    toggleLifestyleFavoriteLoading: updateQueryLoading,
    toggleLifestyleFavoriteErrorMessage: updateQueryErrorMessage,
    toggleLifestyleFavorite: updateQuery,
    toggleLifestyleFavoriteReset: updateQueryReset
  };
}

export default useToggleLifestyleFavorite;
