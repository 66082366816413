import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { LocalForage } from '../../../../utils/LocalForage';

import { UserID } from '../../../users/usersTypes';

import { FetchLifestylesSetByUserIdScopeType } from '../../lifestylesSetsTypes';

import {
  FetchLifestylesSetByUserIdError,
  FetchLifestylesSetByUserIdResponse,
  LifestylesSetByUserIdItemOptimisticUpdateType
} from './types';

import { useUpdateLifestylesSetCache } from './hooks/useUpdateLifestylesSetCache';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { parseRequestError } from '../../../../utils/parseRequestError';
import { fetchItemsByUserId } from '../../../common/hooks/base/reactQuery/baseActions/fetchItemsByUserId';

interface LifestylesSetByUserIdOptions<LifestylesSetByUserIdItemType> {
  cacheKey: string;
  query: string;
  userId: UserID;
  scope?: FetchLifestylesSetByUserIdScopeType;
  options?: {
    cacheTime?: number;
    onSuccess?: (
      data: FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
    ) => void;
  };
}

const itemKey = 'lifestylesSetByUserId';

function useLifestylesSetByUserId<LifestylesSetByUserIdItemType>({
  cacheKey,
  query,
  userId,
  scope,
  options = {}
}: LifestylesSetByUserIdOptions<LifestylesSetByUserIdItemType>) {
  const currentUser = useCurrentUser();

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<
          FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
        >(cacheKey)
    );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, { userId, scope }];
  }, [cacheKey, userId, scope]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    FetchLifestylesSetByUserIdResponse<
      LifestylesSetByUserIdItemType &
        LifestylesSetByUserIdItemOptimisticUpdateType
    >,
    FetchLifestylesSetByUserIdError
  >(
    fullCacheKey,
    () =>
      fetchItemsByUserId({
        query,
        userId,
        scope
      }),
    {
      enabled: placeholderDataFetched,
      cacheTime: options.cacheTime,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        if (
          currentUser.get('id') === userId &&
          !data?.[itemKey]?.optimisticUpdate
        ) {
          LocalForage.setItem<
            FetchLifestylesSetByUserIdResponse<LifestylesSetByUserIdItemType>
          >(cacheKey, data);
        }
      },
      placeholderData: () => {
        if (placeholderData && currentUser.get('id') === userId) {
          return placeholderData;
        }
      }
    }
  );

  const updateLifestylesSetCache = useUpdateLifestylesSetCache<
    LifestylesSetByUserIdItemType &
      LifestylesSetByUserIdItemOptimisticUpdateType
  >({
    fullCacheKey,
    itemKey
  });

  return {
    lifestylesSet: data?.[itemKey] || null,
    lifestylesSetError: parseRequestError(error),
    lifestylesSetFetched: isFetched,
    lifestylesSetLoading: isLoading,
    lifestylesSetIsPlaceholderData: isPlaceholderData,
    updateLifestylesSetCache
  };
}

export default useLifestylesSetByUserId;
