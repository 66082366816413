import React from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { LifestyleAttachModalButtonLifestyle } from './LifestyleAttachModalButton.types';

import { useLifestyleAttach } from './hooks/useLifestyleAttach';

import { SelectProjectAndTaskModalButton } from '../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { lifestylesKeys, words } from '../../../../../locales/keys';

interface LifestyleAttachModalButtonProps {
  lifestyle: LifestyleAttachModalButtonLifestyle;
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
}

function LifestyleAttachModalButton({
  lifestyle,
  className,
  i18nText,
  icon,
  iconClassName,
  tooltipI18nText
}: LifestyleAttachModalButtonProps) {
  const {
    lifestyleAttach,
    lifestyleAttachError,
    lifestyleAttachIsLoading,
    lifestyleAttachReset
  } = useLifestyleAttach({ lifestyle });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      i18nSubmitText={words.attach}
      i18nTitle={lifestylesKeys.attachSelected}
      tooltipSingleton
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.LEFT}
      isLoading={lifestyleAttachIsLoading}
      errorMessage={lifestyleAttachError}
      onClose={lifestyleAttachReset}
      onSubmit={lifestyleAttach}
    />
  );
}

export default LifestyleAttachModalButton;
