import { gql } from 'graphql-request';

import { LifestyleFavorite } from '../lifestylesTypes';

export interface ToggleLifestyleFavoriteQueryResponse {
  favorite: LifestyleFavorite;
}

export const TOGGLE_LIFESTYLE_FAVORITE_QUERY = gql`
  mutation ToggleLifestyleFavorite($uuid: ID!) {
    toggleLifestyleFavorite(input: { uuid: $uuid }) {
      clientMutationId
      status
      recordUuid
      record {
        favorite
      }
      errors {
        fullMessages
      }
    }
  }
`;
