import React, { useCallback } from 'react';
import map from 'lodash/map';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../helpers/Icon';
import { IconWithTooltip } from '../../../../../../../helpers/IconWithTooltip';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';
import {
  LifestyleRenderTypeIcons,
  LifestylesPermissions
} from '../../../../../lifestylesConstants';

import { LifestylesListItemPreviewLifestyle } from './LifestylesListItemPreview.types';

import { librariesKeys } from '../../../../../../../locales/keys';

import { LifestylePath } from '../../../../../LifestylePath';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb320x320,
  '2x': ImageItemImageVersions.MiniThumb640x640,
  '3x': ImageItemImageVersions.MiniThumb960x960
};

const realAspectRatioSrcSetVersions = {
  '1x': ImageItemImageVersions.BigThumb538x435,
  '2x': ImageItemImageVersions.BigThumb538x435,
  '3x': ImageItemImageVersions.FullScreenThumb1000x850
};

interface LifestylesListItemPreviewProps {
  lifestyle: LifestylesListItemPreviewLifestyle;
  lifestylePagePath?: string;
  onLightboxOpen?: () => void;
  withRealAspectRatio?: boolean;
}

function LifestylesListItemPreview({
  lifestyle,
  lifestylePagePath,
  onLightboxOpen,
  withRealAspectRatio
}: LifestylesListItemPreviewProps) {
  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      e.preventDefault();
      onLightboxOpen?.();
    },
    [onLightboxOpen]
  );

  return (
    <div className="relative rounded-md overflow-hidden aspect-w-1 aspect-h-1">
      {lifestyle.image?.file && Files.isImage(lifestyle.image?.file) ? (
        <NextLinkHelper
          className="absolute inset-0"
          href={lifestylePagePath || LifestylePath.show(lifestyle.nanoId)}
          onClick={onLightboxOpen ? handleOpenLightbox : undefined}
        >
          <ImageHelper
            className="absolute inset-0 h-full w-full object-contain bg-gray-500/10"
            src={lifestyle.image.file}
            version={ImageItemImageVersions.MiniThumb320x320}
            srcSetVersions={
              withRealAspectRatio
                ? realAspectRatioSrcSetVersions
                : srcSetVersions
            }
            alt={lifestyle.name}
            loading="lazy"
          />
          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLE_PREMIUM_CONTENT_AT}
          >
            <div className="flex gap-1 pointer-events-auto p-2">
              {map(lifestyle.renderTypes, (type) => (
                <Icon
                  key={type.name}
                  icon={LifestyleRenderTypeIcons[type.name]}
                />
              ))}
            </div>
          </CheckPermissions>

          <div className="absolute z-0 inset-0 pointer-events-none flex flex-col justify-between">
            <div className="flex gap-1 pointer-events-auto p-2">
              <CheckPermissions
                action={LifestylesPermissions.READ_LIFESTYLE_PREMIUM_CONTENT_AT}
              >
                {lifestyle.premiumContentAt ? (
                  <div className="p-1 rounded bg-gray-900/75 text-yellow-500">
                    <IconWithTooltip
                      icon={IconsEnum.CROWN_SOLID}
                      iconClassName="h-5 w-5"
                      i18nText={librariesKeys.premium}
                    />
                  </div>
                ) : null}
              </CheckPermissions>
            </div>
            <div className="flex gap-1 pointer-events-auto p-2 justify-end">
              <CheckPermissions
                action={LifestylesPermissions.READ_LIFESTYLE_RENDER_TYPES}
              >
                {map(lifestyle.renderTypes, (type) => (
                  <Icon
                    key={type.name}
                    icon={LifestyleRenderTypeIcons[type.name]}
                  />
                ))}
              </CheckPermissions>
            </div>
          </div>
        </NextLinkHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default LifestylesListItemPreview;
