import { gql } from 'graphql-request';

import {
  LifestylesSetID,
  LifestylesSetSelectedLifestylesID,
  LifestylesSetSelectedLifestylesLifestyleID,
  LifestylesSetSelectedLifestylesLifestyleUUID,
  LifestylesSetSelectedLifestylesUUID,
  LifestylesSetUUID
} from '../lifestylesSetsTypes';

export interface ToggleLifestyleInLifestylesSetQueryResponse {
  id: LifestylesSetID;
  uuid: LifestylesSetUUID;
  selectedLifestyles: {
    id: LifestylesSetSelectedLifestylesID;
    uuid: LifestylesSetSelectedLifestylesUUID;
    lifestyle: {
      id: LifestylesSetSelectedLifestylesLifestyleID;
      uuid: LifestylesSetSelectedLifestylesLifestyleUUID;
    };
  }[];
}

export const TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY = gql`
  mutation ToggleLifestyleInLifestylesSet(
    $uuid: ID!
    $lifestyleId: ID!
    $clientMutationId: String
  ) {
    toggleLifestyleInLifestylesSet(
      input: {
        uuid: $uuid
        lifestyleId: $lifestyleId
        clientMutationId: $clientMutationId
      }
    ) {
      status
      record {
        id
        uuid
        selectedLifestyles {
          id
          uuid
          lifestyle {
            id
            uuid
            name
            image {
              uuid
              file
            }
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
