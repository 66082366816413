import React, { Fragment } from 'react';

import {
  FetchLifestylesCacheKeys,
  OnSelectedLifestylesSidebarCloseAction,
  OnSelectedLifestylesSidebarOpenAction,
  UpdateLifestyleCacheAction
} from '../../../../../lifestylesTypes';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { LifestylesListItemLightboxDownloadButton } from '../LifestylesListItemLightboxDownloadButton';
import { LifestylesListItemLightboxFavoriteButton } from '../LifestylesListItemLightboxFavoriteButton';
import { LifestylesListItemLightboxSelectButton } from '../LifestylesListItemLightboxSelectButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  LifestylesListItemLightboxBottomButtonsLifestyle,
  LifestylesListItemLightboxBottomButtonsLifestylesSet
} from './LifestylesListItemLightboxBottomButtons.types';

import { LifestylesPermissions } from '../../../../../lifestylesConstants';

interface LifestylesListItemLightboxBottomButtonsDefaultProps {
  lifestyle: LifestylesListItemLightboxBottomButtonsLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  updateLifestyleCache?: UpdateLifestyleCacheAction<LifestylesListItemLightboxBottomButtonsLifestyle>;
}

interface LifestylesListItemLightboxBottomButtonsWithSelectProps {
  lifestylesSet: LifestylesListItemLightboxBottomButtonsLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemLightboxBottomButtonsLifestylesSet>;
}

interface LifestylesListItemLightboxBottomButtonsWithoutSelectProps {
  lifestylesSet?: never;
  lifestylesSetCacheKeys?: never;
  onSelectedLifestylesSidebarOpen?: never;
  onSelectedLifestylesSidebarClose?: never;
  updateLifestylesSetCache?: never;
}

type LifestylesListItemLightboxBottomButtonsProps =
  LifestylesListItemLightboxBottomButtonsDefaultProps &
    (
      | LifestylesListItemLightboxBottomButtonsWithSelectProps
      | LifestylesListItemLightboxBottomButtonsWithoutSelectProps
    );

function LifestylesListItemLightboxBottomButtons({
  lifestyle,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  updateLifestyleCache
}: LifestylesListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      {onSelectedLifestylesSidebarOpen && (
        <LifestylesListItemLightboxSelectButton
          lifestyle={lifestyle}
          lifestylesSet={lifestylesSet}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
          onSelectedLifestylesSidebarOpen={onSelectedLifestylesSidebarOpen}
          onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
          updateLifestylesSetCache={updateLifestylesSetCache}
        />
      )}
      <LifestylesListItemLightboxFavoriteButton
        lifestyle={lifestyle}
        lifestylesCacheKeys={lifestylesCacheKeys}
        lifestylesSetCacheKeys={lifestylesSetCacheKeys}
        updateLifestyleCache={updateLifestyleCache}
      />
      <CheckPermissions
        action={LifestylesPermissions.READ_LIFESTYLE_DOWNLOAD_BUTTON}
      >
        <LifestylesListItemLightboxDownloadButton lifestyle={lifestyle} />
      </CheckPermissions>
    </Fragment>
  );
}

export default LifestylesListItemLightboxBottomButtons;
