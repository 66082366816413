import { gql } from 'graphql-request';

import { ProjectNanoID, ProjectUUID } from '../projectsTypes';

export interface SubmitSelectedLifestylesToProjectQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
}

export const SUBMIT_SELECTED_LIFESTYLES_TO_PROJECT_QUERY = gql`
  mutation SubmitSelectedLifestylesToProject(
    $uuid: ID!
    $selectedLifestyleIds: [ID!]!
  ) {
    submitSelectedLifestylesToProject(
      input: { uuid: $uuid, selectedLifestyleIds: $selectedLifestyleIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
