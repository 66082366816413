import React from 'react';

import { LifestylesListItemLightboxTitleLifestyle } from './LifestylesListItemLightboxTitle.types';

import { TextWithTooltip } from '../../../../../../../helpers/TextWithTooltip';
import { Translate } from '../../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { LifestylesPermissions } from '../../../../../lifestylesConstants';

interface LifestylesListItemLightboxTitleProps {
  lifestyle: LifestylesListItemLightboxTitleLifestyle;
}

function LifestylesListItemLightboxTitle({
  lifestyle
}: LifestylesListItemLightboxTitleProps) {
  return (
    <div className="flex-1 overflow-hidden p-1.5 text-left">
      <div className="text-sm">
        <div className="text-white truncate">
          {lifestyle.blocked ? (
            <span className="bg-red-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.blocked" />
            </span>
          ) : null}
          <TextWithTooltip
            text={lifestyle.name}
            className="font-medium"
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-300 truncate">
          {lifestyle.nda ? (
            <span className="bg-yellow-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.nda" />
            </span>
          ) : null}
          {lifestyle.category ? (
            <TextWithTooltip
              text={lifestyle.category.localizedName}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          ) : null}
        </div>

        <CheckPermissions
          action={LifestylesPermissions.READ_LIFESTYLES_LIGHT_BOX_TITLE_AUTHOR}
        >
          {lifestyle.author?.localizedName ? (
            <div className="text-gray-300 truncate">
              <TextWithTooltip
                text={lifestyle.author.localizedName}
                tooltipPlacement={TooltipPlacement.BOTTOM}
              />
            </div>
          ) : null}
        </CheckPermissions>
      </div>
    </div>
  );
}

export default LifestylesListItemLightboxTitle;
