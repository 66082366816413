import {
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus,
  ProjectGqlError,
  FetchProjectCacheKey,
  ProjectUUID,
  ProjectSelectedLifestylesID,
  ProjectNanoID
} from '../../projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedLifestylesToProjectInput {
  uuid: ProjectUUID | ProjectNanoID;
  selectedLifestyleIds: ProjectSelectedLifestylesID[];
}

interface SubmitSelectedLifestylesToProjectResponse<
  SubmitSelectedLifestylesToProjectRecordType
> {
  SubmitSelectedLifestylesToProject: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: SubmitSelectedLifestylesToProjectRecordType;
    errors: SubmitSelectedLifestylesToProjectErrors;
  };
}

interface SubmitSelectedLifestylesToProjectErrors {
  fullMessages: ProjectGqlError;
}

interface SubmitSelectedLifestylesToProjectOptions {
  query: UpdateProjectGqlQuery;
  cacheKeys: FetchProjectCacheKey[];
}

const action = 'submitSelectedLifestylesToProject';

function useSubmitSelectedLifestylesToProject<
  SubmitSelectedLifestylesToProjectRecordType
>({ query, cacheKeys }: SubmitSelectedLifestylesToProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedLifestylesToProjectInput,
    SubmitSelectedLifestylesToProjectResponse<SubmitSelectedLifestylesToProjectRecordType>,
    SubmitSelectedLifestylesToProjectErrors,
    SubmitSelectedLifestylesToProjectRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedLifestylesToProjectData: createQueryData,
    submitSelectedLifestylesToProjectError: createQueryError,
    submitSelectedLifestylesToProjectErrorMessage: createQueryErrorMessage,
    submitSelectedLifestylesToProjectLoading: createQueryLoading,
    submitSelectedLifestylesToProject: createQuery,
    submitSelectedLifestylesToProjectReset: createQueryReset
  };
}

export default useSubmitSelectedLifestylesToProject;
